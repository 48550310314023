import { Component, Input } from '@angular/core';

@Component({
  selector: 'b-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent {
  @Input()
  blocks: any[];

  setActive(block: any) {
    for (let b of this.blocks) {
      if (b !== block) {
        b.active = false;
      }
    }

    block.active = !block.active;
  }

  @Input()
  sideview: boolean;
}
