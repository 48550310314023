import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocksComponent } from './blocks/blocks.component';
import { BlockComponent } from './block/block.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BlocksComponent, BlockComponent],
  exports: [BlocksComponent],
})
export class BlocksModule {}
