<div class="app">
  <div class="app__controls">
    <form [formGroup]="data">
      <div>
        <label>
          Floors:
          <input type="number" formControlName="floors">
        </label>
      </div>
    </form>
    <form [formGroup]="view">
      <div>
        <label>
          <input type="checkbox" formControlName="sideview">
          Zijkant
        </label>
      </div>
    </form>
  </div>
  <div class="app__preview">
    <b-blocks
      [blocks]="blocks$ | async"
      [sideview]="view.value.sideview"
    ></b-blocks>
  </div>
</div>
