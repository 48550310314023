import { Component, Input } from '@angular/core';

@Component({
  selector: 'b-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent {
  @Input()
  label: string;

  @Input()
  active: boolean;

  @Input()
  sideview: boolean;
}
