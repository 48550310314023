import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'buildy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  data = new FormGroup({
    floors: new FormControl(1),
  });

  view = new FormGroup({
    sideview: new FormControl(false),
  });

  blocks$ = this.data.valueChanges.pipe(
    startWith(this.data.getRawValue()),
    map((data) => Array.from({ length: data.floors }, (v, k) => ({
      label: k
    }))),
  );
}
