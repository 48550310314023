<div class="b-blocks-container" [ngClass]="{ 'sideview': sideview }">
  <div class="b-stack-item" *ngFor="let block of blocks">
    <b-block
      [label]="block.label"
      (click)="setActive(block)"
      [ngClass]="{ 'active': block.active }"
      [active]="block.active"
      [sideview]="sideview"
    ></b-block>
  </div>
</div>
